import React from 'react'
import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'

import { ErrorBoundaryWithLogger } from '../../../frontend-lib/Log'

const PrivacidadEs = () => {
    const lang = 'es'
    return (
        <ErrorBoundaryWithLogger serverUrl={process.env.GATSBY_SERVER_URL} logName="sitio web">
            <Layout lang={lang}>
                <SEO
                    title={'Optiwe - Política de Privacidad'}
                    description={'Política de privacidad del servicio'}
                    lang={'es'}
                    locales={[
                        { hreflang: 'en', href: 'https://optiwe.com/legal/privacy' },
                        { hreflang: 'es', href: 'https://optiwe.com/es/legal/privacy' },
                        { hreflang: 'x-default', href: 'https://optiwe.com/legal/privacy' }
                    ]}
                />

                <main className="container-fluid ow-banner">
                    {/*
                    ^*********
                    * CABECERA *
                    **********
                    */}
                    <Nav
                        lang={lang}
                        showButtons
                    />

                    <div className="container ow-banner__content">
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12 ow-banner__home-content">
                                <h1>Política de Privacidad de Optiwe, Inc.</h1>
                                <h2>Efectiva a partir del 1 de mayo de 2020</h2>
                                <p>
                                    En Optiwe, Inc. ("Optiwe"), tomamos muy en serio la privacidad y sus datos personales. Por favor, lea esta Política de Privacidad (la "Política") detenidamente.
                                    Esta Política describe cómo recopilamos, utilizamos, compartimos y procesamos información personal y sus derechos
                                    y opciones con respecto a dicha información, cuando utiliza nuestros sitios web, software de análisis de datos y
                                    otros servicios (en conjunto, los "Servicios"). Al utilizar, participar o acceder a nuestros Servicios, usted
                                    reconoce que acepta y da su consentimiento a las prácticas descritas en esta Política.
                                </p>
                                <h3>1. Por qué y qué información se recopila cuando utiliza nuestros Servicios</h3>
                                <p>
                                    Recopilamos y utilizamos la siguiente información para proporcionar y mejorar nuestros Servicios y como se describe en esta Política de Privacidad. Optiwe también puede utilizar la información para medir el rendimiento
                                    y desarrollar nuevos servicios.
                                </p>
                                <p>
                                    Información de la cuenta:
                                </p>
                                <p>
                                    Recopilamos información asociada con su cuenta, como su contraseña, nombre, empresa, dirección de correo electrónico,
                                    ubicación, número de teléfono, actividad de la cuenta y del sitio web, direcciones IP, identificadores de cookies, horas de inicio de sesión y otra información que proporcione.
                                </p>
                                <p>
                                    Información de los Servicios:
                                </p>
                                <p>
                                    Nuestros servicios de análisis de datos permiten a los clientes y su personal registrar, analizar y compartir
                                    el contenido de llamadas telefónicas, videollamadas, demostraciones en línea, seminarios web, comunicaciones y
                                    datos y documentación relacionados. Estas grabaciones y su análisis pueden contener información personal, como nombres, cargos e información de contacto.
                                    Optiwe puede recopilar, reproducir, procesar, analizar, resumir y divulgar estos archivos, grabaciones y
                                    cualquier resultado de nuestros Servicios con dichos clientes y su personal relevante y otros miembros del equipo, y los clientes pueden compartir esta información con su personal y otros.
                                </p>
                                <h3>2. Cómo utiliza Optiwe la información recopilada</h3>
                                <p>
                                    Recopilamos información relacionada con cómo utiliza los Servicios para ayudarnos a proporcionarle funciones adicionales, monitorear y mejorar los Servicios.
                                    También recopilamos información de los dispositivos que utiliza para acceder a los Servicios, como direcciones IP, el tipo de navegador y dispositivo que utiliza e identificadores asociados con sus dispositivos.
                                    Utilizaremos técnicas de aprendizaje automático en los datos de respuesta, datos de metadatos y datos de cookies, para proporcionar a los usuarios información útil y relevante de los datos recopilados utilizando nuestros Servicios, para desarrollar funciones, mejorar nuestros servicios y desarrollar productos de datos agregados.
                                    También utilizamos tecnologías como cookies para proporcionar, mejorar, proteger y promocionar nuestros Servicios. Por ejemplo, las cookies nos ayudan a recordar su nombre de usuario para su próxima visita, a comprender cómo interactúa con nuestros Servicios y a mejorarlos en función de esa información.
                                    Siempre puede optar por no divulgar información, pero tenga en cuenta que es posible que se necesite cierta información para crear una cuenta o aprovechar algunas de nuestras funciones.
                                </p>
                                <h3>3. Con quién comparte Optiwe su información?</h3>
                                <p>
                                    No compartimos su información o datos con terceros fuera de Optiwe, excepto en las siguientes circunstancias limitadas:
                                    Su información de cuenta y datos pueden ser compartidos con el administrador principal(es) y también pueden ser
                                    visibles para otros miembros en su organización o su equipo o con quienes colabore. Sus
                                    administrador(es) podrán ver los datos de su cuenta, cambiar sus contraseñas, suspender, transferir o
                                    cancelar su cuenta o restringir su configuración. Por favor, consulte las políticas internas de su organización si tiene preguntas al respecto.
                                    Usted o sus administradores de equipo también pueden dar o dirigirnos a dar acceso a terceros a su
                                    información y cuenta, por ejemplo, a través de plataformas de CRM de terceros.
                                    Para ayudarnos a proporcionar ciertos aspectos de nuestros servicios, utilizamos afiliados y socios clave de confianza. Celebramos acuerdos de confidencialidad y procesamiento de datos con los socios para asegurarnos de que cumplan con altos niveles de confidencialidad y mejores prácticas en estándares de privacidad y seguridad, y revisamos regularmente estos estándares y prácticas.
                                    A su solicitud, compartimos su información o datos si elige utilizar una integración junto con los servicios de Optiwe, Inc., en la medida necesaria para facilitar ese uso.
                                </p>
                                <p>También podemos tener que compartir información o datos para:</p>
                                <p>i. cumplir con cualquier ley aplicable, regulación, proceso legal o solicitud gubernamental exigible.</p>
                                <p>ii. hacer cumplir políticas aplicables, incluyendo la investigación de posibles violaciones.</p>
                                <p>iii. detectar, prevenir o abordar fraudes, problemas de seguridad o técnicos.</p>
                                <p>
                                    iv. proteger contra daños a los derechos, propiedad o seguridad de nuestros usuarios, el público o a Optiwe y/o
                                    según lo requiera o permita la ley.
                                </p>
                                <p>
                                    v. facilitar una venta, fusión o cambio de control de toda o parte de nuestra empresa o negocio o en
                                    preparación para cualquiera de estos eventos.
                                </p>
                                <p>
                                    También podemos anonimizar o agregar su información personal para que no sea identificada individualmente ("Datos Anonimizados"), y podemos utilizar dichos Datos Anonimizados para mejorar nuestros servicios.
                                    Optiwe puede proporcionar estos Datos Anonimizados a nuestros socios, quienes pueden utilizar esa información en forma anonimizada para comprender con qué frecuencia y de qué manera las personas utilizan nuestros Servicios, de modo que también puedan brindarle una experiencia en línea óptima y/o mejores servicios.
                                    Nunca revelaremos información de uso agregada a un socio sin su consentimiento ni de manera que lo identifique personalmente como individuo, excepto según sea necesario para realizar los Servicios o según se establezca de otra manera en esta Política.
                                </p>
                                <h3>4. Cómo almacenamos y protegemos su información</h3>
                                <p>
                                    Implementamos una variedad de medidas de seguridad para mantener la seguridad de su información personal cuando utiliza nuestros 'Servicios'. Ofrecemos el uso de un servidor seguro. Toda la información sensible o de tarjetas de crédito que se proporciona se transmite mediante tecnología de seguridad de capa de transporte (TLS) y luego se almacena en nuestra base de datos para ser accedida solo por aquellos autorizados con derechos de acceso especiales a nuestros sistemas, y están obligados a mantener la información confidencial. Utilizamos una combinación de infraestructura de Amazon AWS y Google Cloud para proporcionar nuestros servicios y almacenar los datos. Si tiene alguna pregunta sobre la seguridad de su información personal, puede ponerse en contacto con nosotros en info@optiwe.com.
                                </p>
                                <p>
                                    - Los datos personales que recopilamos se almacenan en infraestructura proporcionada por Amazon y Google dentro de los Estados Unidos.
                                </p>
                                <p>
                                    - Amazon y Google implementan y mantienen estándares de seguridad líderes en la industria.
                                </p>
                                <p>
                                    - Amazon y Google implementan y mantienen estándares de seguridad líderes en la industria.
                                </p>
                                <p>
                                    - Amazon y Google implementan y mantienen estándares de seguridad líderes en la industria.
                                </p>
                                <p>
                                    - Puede obtener más información sobre Amazon AWS y los servicios de Google Cloud y sus características de seguridad en su página web.
                                </p>
                                <p>
                                    - Sin embargo, ningún medio de transmisión por Internet o almacenamiento electrónico es 100% seguro, y aunque nos esforzamos por utilizar medios comercialmente razonables para proteger la información personal, no podemos garantizar su seguridad absoluta.
                                </p>
                                <p>
                                    - Si accede a nuestros Servicios a través de una plataforma de CRM de terceros o un calendario en la nube (Slack, SalesForce), también debe proteger su cuenta con credenciales de cuenta seguras y evitar el acceso no autorizado a su cuenta e información personal.
                                </p>
                                <h3>5. Retención</h3>
                                <p>
                                    Optiwe conservará la información durante el tiempo que sea necesario para proporcionarle los Servicios o para cumplir con la ley aplicable. Si interrumpe el Servicio, esta información puede eliminarse previa solicitud o según los términos de nuestros acuerdos comerciales.
                                    Pero tenga en cuenta:
                                </p>
                                <p>
                                    (1) podría haber cierta latencia en la eliminación de esta información de nuestros servidores y almacenamiento de respaldo;
                                </p>
                                <p>
                                    (2) no eliminaremos los Datos Anonimizados y podemos seguir utilizándolos según se describe en esta Política de Privacidad; y
                                </p>
                                <p>
                                    (3) podemos conservar la información si es necesario para cumplir con nuestras obligaciones legales, resolver disputas, gestionar riesgos de seguridad o hacer cumplir nuestros acuerdos.
                                </p>
                                <h3>6. Cambios</h3>
                                <p>
                                    Si estamos involucrados en una reorganización, fusión, adquisición o venta de nuestros activos, quiebra u evento similar, su información puede transferirse como parte de la evaluación y concreción de ese acuerdo.
                                    Le notificaremos (por ejemplo, a través de un mensaje a la dirección de correo electrónico asociada con su cuenta) de cualquier acuerdo de este tipo en el que no seamos la entidad sobreviviente de dicha transacción, y le describiremos sus opciones.
                                    Podemos revisar esta Política de vez en cuando y publicaremos la versión más actual en nuestro sitio web.
                                    Si una revisión reduce significativamente sus derechos, le notificaremos. Al utilizar los Servicios después de cualquier cambio, usted reconoce su acuerdo con dichos cambios y que el uso de la recopilación de información está sujeto a la Política vigente cuando se utiliza dicha información.
                                </p>
                                <h3>7. Acceso y contacto</h3>
                                <p>
                                    A través de la configuración de su cuenta, puede acceder y, en algunos casos, editar o eliminar cierta información que proporciona a Optiwe.
                                    Tenga en cuenta que la información que puede ver, actualizar o eliminar puede cambiar a medida que cambien los Servicios.
                                    Si su información personal nos ha sido proporcionada por uno de nuestros clientes, comuníquese con ese cliente para solicitar acceso, corrección o eliminación de su información.
                                    Si tiene alguna pregunta sobre esta política de privacidad, las prácticas de este sitio web o su trato con este sitio web, puede ponerse en contacto con nosotros en info@optiwe.com
                                </p>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </ErrorBoundaryWithLogger>
    )
}
export default PrivacidadEs
